import { Component } from '@angular/core';

@Component({
  selector: 'app-add-new-group-chat',
  standalone: true,
  imports: [],
  templateUrl: './add-new-group-chat.component.html',
  styleUrl: './add-new-group-chat.component.css'
})
export class AddNewGroupChatComponent {

}
