@if (received) {
   <div class="flex w-full justify-start items-start gap-2.5">
      <div class="flex-shrink-0 flex size-10 img-container rounded-full border-[1px] border-primaryBlack/20">
         <img [src]="participantImageLink" class="rounded-full w-full h-full object-cover" />
      </div>   

      <div class="flex flex-col gap-1 w-full max-w-[320px] items-start">
         <div class="flex items-center justify-end space-x-2 rtl:space-x-reverse">
            <span class="text-sm font-semibold text-gray-900 dark:text-white">{{message?.senderDetails.FirstName}} {{message?.senderDetails.LastName}}</span>
            <span class="text-sm font-normal text-gray-500 dark:text-gray-400">11:46</span>
         </div>
         @if (content.includes("https://s3.eu-north-")) {
            <div class="flex flex-col leading-1.5 p-4 border-[1px] border-greyScaleBorderDefault bg-greyScaleSurfaceDisabled rounded-b-xl rounded-l-xl dark:bg-gray-700">
               <img [src]="content"/>
            </div>
         } @else {
            @if (message.type === 'img') {
               <div class="flex flex-col leading-1.5 p-4 border-[1px] border-greyScaleBorderDefault bg-greyScaleSurfaceDisabled rounded-b-xl rounded-l-xl dark:bg-gray-700">
                  <img [src]="content"/>               
               </div>
            }
            @if (message.type === 'txt') {
               <div class="flex flex-col leading-1.5 p-4 border-[1px] border-greyScaleBorderDefault bg-greyScaleSurfaceDisabled rounded-b-xl rounded-l-xl dark:bg-gray-700">
                  <p class="text-sm font-normal text-gray-900 dark:text-white">{{content}}</p>
               </div>
            }
         }
         <span class="text-sm font-normal text-gray-500 dark:text-gray-400">Delivered</span>
      </div>
      <!-- <button id="dropdownMenuIconButton" data-dropdown-toggle="dropdownDots" data-dropdown-placement="bottom-start" class="inline-flex self-center items-center p-2 text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-900 dark:hover:bg-gray-800 dark:focus:ring-gray-600" type="button">
         <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 4 15">
            <path d="M3.5 1.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 6.041a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 5.959a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"/>
         </svg>
      </button> -->

      <div id="dropdownDots" class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-40 dark:bg-gray-700 dark:divide-gray-600">
         <ul class="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownMenuIconButton">
            <li>
               <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Reply</a>
            </li>
            <li>
               <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Forward</a>
            </li>
            <li>
               <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Copy</a>
            </li>
            <li>
               <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Report</a>
            </li>
            <li>
               <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Delete</a>
            </li>
         </ul>
      </div>
  </div>
} @else {
    <div class="flex w-full justify-end items-start gap-2.5">
        <!-- <button id="dropdownMenuIconButton" data-dropdown-toggle="dropdownDots" data-dropdown-placement="bottom-start" class="inline-flex self-center items-center p-2 text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-900 dark:hover:bg-gray-800 dark:focus:ring-gray-600" type="button">
            <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 4 15">
               <path d="M3.5 1.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 6.041a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 5.959a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"/>
            </svg>
        </button> -->
        <div class="flex flex-col gap-1 w-full max-w-[320px] items-end">
            <div class="flex items-center justify-end space-x-2 rtl:space-x-reverse">
               <span class="text-sm font-normal text-gray-500 dark:text-gray-400">11:46</span>
            </div>
            @if (content.includes("https://s3.eu-north-")) {
               <div class="flex flex-col leading-1.5 p-4 border-[1px] border-greyScaleBorderDefault bg-greyScaleSurfaceDisabled rounded-b-xl rounded-l-xl dark:bg-gray-700">
                  <img [src]="content"/>
               </div>
            } @else {
               @if (message.type === 'img') {
                  <div class="flex flex-col leading-1.5 p-4 border-[1px] border-greyScaleBorderDefault bg-greyScaleSurfaceDisabled rounded-b-xl rounded-l-xl dark:bg-gray-700">
                     <img [src]="content"/>               
                  </div>
               }
               @if (message.type === 'txt') {
                  <div class="flex flex-col leading-1.5 p-4 border-[1px] border-greyScaleBorderDefault bg-greyScaleSurfaceDisabled rounded-b-xl rounded-l-xl dark:bg-gray-700">
                     <p class="text-sm font-normal text-gray-900 dark:text-white">{{content}}</p>
                  </div>
               }
            }
            <span class="text-sm font-normal text-gray-500 dark:text-gray-400">Delivered</span>
        </div> 

        <div id="dropdownDots" class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-40 dark:bg-gray-700 dark:divide-gray-600">
           <ul class="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownMenuIconButton">
              <li>
                 <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Reply</a>
              </li>
              <li>
                 <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Forward</a>
              </li>
              <li>
                 <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Copy</a>
              </li>
              <li>
                 <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Report</a>
              </li>
              <li>
                 <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Delete</a>
              </li>
           </ul>
        </div>
    </div>
}
 