import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ChatItemComponent } from 'src/app/components/chat-item/chat-item.component';
import { ButtonComponent } from "../../../components/button/button.component";
import { ChatMessageComponent } from "../../../components/chat-message/chat-message.component";
import { ChatService } from 'src/app/services/chat.service';
import { FormsModule } from '@angular/forms';
import { Message } from 'src/app/models/message';
import { CommonModule } from '@angular/common';
import { animate, group, state, style, transition, trigger } from '@angular/animations';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddNewChatComponent } from 'src/app/components/modals/add-new-chat/add-new-chat.component';
import { AddNewGroupChatComponent } from 'src/app/components/modals/add-new-group-chat/add-new-group-chat.component';
import { NgToastService } from 'ng-angular-popup';
import { Chat } from 'src/app/models/chat';
import { EmployeeService } from 'src/app/services/employee.service';
import { Employee } from 'src/app/models/employee';
import { SafeResourceUrl } from '@angular/platform-browser';
import { AdminService } from 'src/app/services/admin.service';
import { ChatDTO } from 'src/app/dtos/chatDTO';
import { last, timestamp } from 'rxjs';

@Component({
  selector: 'app-chat',
  standalone: true,
  imports: [ChatItemComponent, ButtonComponent, ChatMessageComponent, FormsModule, CommonModule],
  templateUrl: './chat.component.html',
  styleUrl: './chat.component.css',
  animations: [
    trigger('flyInOut', [
      state('in', style({
        width: '*',
        transform: 'translateX(0)', opacity: 1,
        overflow: 'hidden'
      })),
      transition(':enter', [
        style({ width: 10, transform: 'translateY(50px)', opacity: 0, overflow: 'hidden' }),
        group([
          animate('0.2s ease', style({
            transform: 'translateY(0)',
            width: '*'
          })),
          animate('0.2s ease', style({
            opacity: 1
          }))
        ])
      ]),
      transition(':leave', [
        group([
          animate('0.2s ease', style({
            transform: 'translateY(50px)',
            width: 10
          })),
          animate('0.2s ease', style({
            opacity: 0
          }))
        ])
      ])
    ])
  ]
})
export class ChatComponent implements OnInit{

  @ViewChild('videoInput') videoInput!: ElementRef<HTMLInputElement>;
  @ViewChild('documentInput') documentInput!: ElementRef<HTMLInputElement>;
  @ViewChild('imageInput') imageInput!: ElementRef<HTMLInputElement>;

  public uploadedFile: File | null = null;
  public filePreviewUrl: string | null = null;
  public groupChat : boolean = false;
  public imageUploaded : boolean = false;

  public messageMenu : boolean = false;
  public chatsMenu : boolean = false;

  public activeChatId : string = '';
  public activeChat! : ChatDTO;
  public activeChatName : string = '';
  public participant! : Employee;
  public participantImageLink : any;

  public myId = sessionStorage.getItem('adminId');
  public chats : Chat[] = [];
  public organizedChats : ChatDTO[] = [];
  public messageString : string = '';
  public messages: any[] = [];

  public owners : any[] = [];
  public employees : any[] = [];

  constructor(private chatService : ChatService,
    private modalService : NgbModal,
    private toast : NgToastService,
    private employeeService : EmployeeService,
    private elementRef: ElementRef,
    private adminService : AdminService
  ) {}

  ngOnInit(): void {
    this.getChats();
  }

  public initializeWebSocketConnection() : void {
    const adminId = sessionStorage.getItem('adminId');
    if(adminId){
      this.chatService.connect(adminId, this.activeChatId);
      this.subscribeToChatMessages();
    }
  }

  public subscribeToChatMessages() : void {
    this.chatService.getMessages().subscribe(
      (response : any) => {
        let sender;

        if(this.activeChat.ParticipantRole === 'owner'){
          sender = this.activeChat.ParticipantDetails.ID;
        } else {
          sender = this.activeChat.ParticipantDetails.EmployeeID;
        }
        this.messages.push({
          sender: sender,
          content: response.payload,
          senderRole : this.activeChat.ParticipantRole,
          senderDetails : this.activeChat.ParticipantDetails,
          timestamp: Date.now()
        });      
      }
    )
  }
  
  public getChats() : void {
    this.chatService.getChats().subscribe(
      (response : any) => {
        console.log("CHATS: ", response);
        this.chats = response
        this.getAllEmployeesAndOwners();
      }
    )
  }

  public organizeChats() : void {
    this.organizedChats = this.chats.map(chat => {
      const participantDetails = this.getParticipantDetails(chat);
      console.log("UCESNIK: ", participantDetails);
      
      
      let chatName = '';
      let role = participantDetails.role

      if(participantDetails.role === 'owner') {
        console.log("OWNERCINA")
        chatName = participantDetails.details.FirstName + " " + participantDetails.details.LastName
        this.employeeService.getEmployeePicture(true, participantDetails.details.ID).subscribe(
          (imageLink: SafeResourceUrl | null) => {
            this.participantImageLink = imageLink;
          }
        );  
      }

      if(participantDetails.role === 'employee') {
        console.log("ZAPOSLENI")
        console.log("ZAPOSLENI DETALJI: ", participantDetails.details);
        chatName = participantDetails.details.FirstName + " " + participantDetails.details.LastName
        this.employeeService.getEmployeePicture(participantDetails.details.DefaultPicture, participantDetails.details.EmployeeID).subscribe(
          (imageLink: SafeResourceUrl | null) => {
            this.participantImageLink = imageLink;
          }
        ); 
      }

      const lastMsg = chat.Messages.at(-1);
      let lastMsgPayload = ''
      let lastMsgPayloadDate;
      let tmpLastMsgPayloadDateAndTime;
      let lastMsgPayloadDateAndTime = '';

      if(lastMsg) {
        lastMsgPayload = lastMsg.Payload;
        lastMsgPayloadDate = new Date(lastMsg.Timestamp);
        tmpLastMsgPayloadDateAndTime = lastMsgPayloadDate.toISOString().substring(0, 16);
        lastMsgPayloadDateAndTime = `${tmpLastMsgPayloadDateAndTime.split('T')[0]} ${tmpLastMsgPayloadDateAndTime.split('T')[1]}`
      }


      return {
        ...chat,
        ChatName : chatName,
        LastMessage : lastMsgPayload,
        LastMessageDateAndTime : lastMsgPayloadDateAndTime,
        ParticipantDetails : participantDetails.details,
        ParticipantRole : role
      }
    })

    console.log("ORGANIZED: ", this.organizedChats);
  }

  public getAllEmployeesAndOwners() : void {
    this.employeeService.getAllEmployeesAndAdmins().subscribe(
      (response : any) => {
        this.owners = response.Owners;
        this.employees = response.Employees;
        this.organizeChats();
      }
    )
  }

  public openChat(chat : ChatDTO) : void {
    this.messages = [];
    if(chat.ParticipantRole === 'owner'){
      this.employeeService.getEmployeePicture(true, chat.ParticipantDetails.ID).subscribe(
        (imageLink: SafeResourceUrl | null) => {
          this.participantImageLink = imageLink;
          this.activeChat = chat;
          this.activeChatId = chat.ID;

          let msgType;
          const myId = sessionStorage.getItem('adminId')
          chat.Messages.map(message => {
            if(message.Payload.includes("https://s3.eu-north-")){
              msgType = 'img';
            } else {
              msgType = 'txt';
            }
            const msg = {
              sender : message.SenderID,
              content : message.Payload,
              timestamp : message.Timestamp,
              type : msgType,
              senderRole : chat.ParticipantRole,
              senderDetails : chat.ParticipantDetails
            }
            this.messages.push(msg);
          })

          this.initializeWebSocketConnection();
        }
      );  
    } 

    if(chat.ParticipantRole === 'employee'){
      this.employeeService.getEmployeePicture(chat.ParticipantDetails.DefaultPicture, chat.ParticipantDetails.EmployeeID).subscribe(
        (imageLink: SafeResourceUrl | null) => {
          this.participantImageLink = imageLink;
          this.activeChat = chat;
          this.activeChatId = chat.ID;
          this.initializeWebSocketConnection();
        }
      );
    }    
  }

  public getParticipantDetails(chat : Chat) : any {
    const owner = this.owners.find(owner => owner.ID === chat.Participants[0]);

    if(owner) {
      return {
        role : 'owner',
        details : owner
      };
    } else {
      const employee = this.employees.find(employee => employee.EmployeeID === chat.Participants[0]);    
      if(employee) {
        return {
          role : 'employee',
          details : employee
        };
      }
    }
  }

  public sendMessage(event?: Event) : void {

    let msgType;
    const adminId = sessionStorage.getItem('adminId');
    if((this.messageString !== '' || this.uploadedFile) && adminId){
      const date = new Date();
      const messageContent = this.messageString;

      const message: Message = {
        senderID: adminId,
        chatID: this.activeChatId,
        payload: messageContent,
        timestamp: date.getTime(),
      };

      const formData = new FormData();

      if(this.uploadedFile){
        formData.append('image', this.uploadedFile, this.uploadedFile.name);
        msgType = 'Photo';
        console.log("IMG SENT")
        this.chatService.sendMessage(message, formData, msgType).subscribe(
          (response: any) => {
            this.messages.push({
              sender: this.myId,
              content: this.filePreviewUrl,
              type: 'img',
              timestamp: date.getTime(),
            });
          },
          (error) => {
            console.error("Failed to send message:", error);
          }
        );
      } else {
        formData.append('message', messageContent);
        msgType = 'Text'
        this.chatService.sendMessage(message, formData, msgType).subscribe(
          (response: any) => {
            this.messages.push({
              sender: this.myId,
              content: messageContent,
              type: 'txt',
              timestamp: date.getTime(),
            });
          },
          (error) => {
            console.error("Failed to send message:", error);
          }
        );
      }

      this.messageString = '';
      this.imageUploaded = false;
    }
  }

  public triggerFileSelection(type: string): void {
    switch (type) {
      case 'video':
        this.videoInput.nativeElement.click();
        break;
      case 'document':
        this.documentInput.nativeElement.click();
        break;
      case 'image':
        this.imageInput.nativeElement.click();
        break;
      default:
        console.error('Unknown file type:', type);
    }
  }

  public handleFileSelection(event: Event, type: string): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.messageMenu = !this.messageMenu;
      this.uploadedFile = input.files[0];
      this.imageUploaded = true;

      if (this.uploadedFile.type.startsWith('image/')) {
        this.filePreviewUrl = URL.createObjectURL(this.uploadedFile);
      } else if (this.uploadedFile.type.startsWith('video/')) {
        this.filePreviewUrl = URL.createObjectURL(this.uploadedFile);
      } else {
        this.filePreviewUrl = null;
      }
    }
  }

  public clearUploadedFile(): void {
    this.uploadedFile = null;
    this.filePreviewUrl = null;
    this.imageUploaded = false;
  }

  public getAcceptedFileTypes(type: string): string {
    switch (type) {
      case 'video':
        return 'video/*';
      case 'document':
        return '.pdf,.doc,.docx';
      case 'image':
        return 'image/*';
      default:
        return '*/*';
    }
  }

  public openNewChat() : void {
    const modalRef = this.modalService.open(
      AddNewChatComponent,
      { backdrop : 'static', keyboard : true }
    );

    modalRef.componentInstance.chatCreated.subscribe(
      () => {
        this.toast.success("Chat successfully created!", "Success!", 3000)
        this.getChats();
      }
    )
  }

  public createNewGroup() : void {
    this.chatsMenu = false;
    const modalRef = this.modalService.open(
      AddNewGroupChatComponent,
      { backdrop : 'static', keyboard : true}
    );
  }
}
