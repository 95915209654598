import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, catchError, map, of } from 'rxjs';
import { CompanyEmployee } from '../models/companyEmployee';
import { Employee } from '../models/employee';
import { environment } from '../../environments/environment';
import { Engagement } from '../models/engagement';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {

  private skillSource = new Subject<any>();
  skillSelected$ = this.skillSource.asObservable();

  private apiServerUrl = environment.apiServerUrl;

  constructor(private http: HttpClient,
    private sanitizer: DomSanitizer
  ) { }

  public addSkill(skill: any) {
    this.skillSource.next(skill);
  }

  public getAll(): Observable<any> {

    const companyId = sessionStorage.getItem('company');
    const body = { companyId: companyId };
    return this.http.get<any>(`${this.apiServerUrl}/auth/getAllEmployees`);
  }

  public register(employee : CompanyEmployee, companyId : string | null) : Observable<any> {
    return this.http.post<any>(`${this.apiServerUrl}/company/registerEmployee`, employee);
  }

  public getEmployee(userId : string) : Observable<any> { 
    return this.http.get<any>(`${this.apiServerUrl}/auth/findEmployeeById?employeeID=${userId}`);
  }

  public getMatchingEmployeesForPosition(body : any) : Observable<Employee[]>{
    return this.http.post<Employee[]>(`${this.apiServerUrl}/project/findEmployeeForPosition`, body,);
  }

  public editEmployeeProfile(body : any, employeeId : string) : Observable<any> {
    return this.http.patch<any>(`${this.apiServerUrl}/employee/editProfile/${employeeId}?${body.field}=${body.value}`, {})
  } 

  public uploadProfileImage(formData : FormData) : Observable<any> {
    const token = sessionStorage.getItem('token');
    const companyId = sessionStorage.getItem('company')
    const customHeaders = new HttpHeaders({
      'Authorization' : `Bearer ${token}`,
      'companyID' : `${companyId}`
    })

    return this.http.post<any>(`${this.apiServerUrl}/S3/uploadToS3`, formData, { headers : customHeaders });
  }

  public requestDayOff(startDate : number, message: string, numberOfDays : number) : Observable<any> {
    const token = sessionStorage.getItem('token');
    const domain = sessionStorage.getItem('domain')
    const customHeaders = new HttpHeaders({
      'Content-Type' : 'application/json',
      'Authorization' : `Bearer ${token}`,
    });
    return this.http.post<any>(`${this.apiServerUrl}/employee/${domain}/requestDayOff?message=${message}&startDate=${startDate}&daysRequested=${numberOfDays}`, {})
  }

  public addSkillToExistingEmployee(employeeId : string, body : any) : Observable<any> {
    return this.http.patch<any>(`${this.apiServerUrl}/employee/addSkill/${employeeId}`, body)
  }

  public removeSkill(employeeId : string, skillId : string) : Observable<any> {
    return this.http.delete<any>(`${this.apiServerUrl}/employee/removeSkill/${skillId}/${employeeId}`)
  }

  public editSkill(skillId : string, expLevelId : string, employeeId : string){
    return this.http.patch<any>(`${this.apiServerUrl}/employee/updateSkillLevel/${skillId}/${expLevelId}/${employeeId}`, {});
  }

  public getEngagements(employeeId : string) : Observable<any>{
    return this.http.get<any>(`${this.apiServerUrl}/employee/get/all/engagements/admin/${employeeId}`)
  }

  public getEmployeeImage(employeeId : string | undefined, companyId : any) : Observable<string>{
    return this.http.get<string>(`${this.apiServerUrl}/S3/generateS3Link/${employeeId}/${companyId}`)
  }

  public getUserProfilePicutre() : Observable<any>{
    const domain = sessionStorage.getItem('domain')
    return this.http.get<any>(`${this.apiServerUrl}/S3/viewProfilePicture/${domain}`);
  }

  //Change header name when companyId is requested. It should be companyDomain instead of companyId.
  public getClientEngagements() : Observable<Engagement[]> {
    const clientId = sessionStorage.getItem("employeeId");
    return this.http.get<Engagement[]>(`${this.apiServerUrl}/employee/get/all/engagements/${clientId}`)
  }

  public getEmployeePicture(defaultPicture: boolean, employeeId: string): Observable<SafeResourceUrl | null> {
    const company = sessionStorage.getItem('company');
    let companyId;
    let param;
  
    if (defaultPicture) {
      param = "default";
      companyId = "firmegeneralepictures";
    } else {
      param = employeeId;
      companyId = company;
    }
  
    return this.getEmployeeImage(param, companyId).pipe(
      map((response: string) => 
        this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + response)
      ),
      catchError((error: HttpErrorResponse) => {
        console.error("Error while getting S3 image link:", error.message);
        return of(null);
      })
    );
  }

  public deleteEmployee(employeeId : string) : Observable<any> {
    return this.http.delete(`${this.apiServerUrl}/employee/delete/${employeeId}`)
  }

  public addToProject(employeeId : string, projectId : string) : Observable<any> {
    return this.http.get<any>(`${this.apiServerUrl}/project/add/employee/to/project?employeeID=${employeeId}&projectID=${projectId}`);
  }

  public removeFromProject(employeeId : string, projectId : string) : Observable<any>{
    return this.http.get<any>(`${this.apiServerUrl}/project/delete/employee/from/project?employeeID=${employeeId}&projectID=${projectId}`);
  }

  public getAllEmployeesAndAdmins() : Observable<any>{
    return this.http.get<any>(`${this.apiServerUrl}/auth/get/all/users`);
  }
  
}