import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ChatDTO } from 'src/app/dtos/chatDTO';
import { Chat } from 'src/app/models/chat';
import { Employee } from 'src/app/models/employee';
import { AdminService } from 'src/app/services/admin.service';
import { EmployeeService } from 'src/app/services/employee.service';

@Component({
  selector: 'app-chat-item',
  standalone: true,
  imports: [],
  templateUrl: './chat-item.component.html',
  styleUrl: './chat-item.component.css'
})
export class ChatItemComponent implements OnInit{

  @Input() chat! : ChatDTO;

  public chatName : string = '';
  public employeePictureLink : any;

  public owners : any[] = [];
  public employees : any[] = [];

  constructor(private employeeService : EmployeeService,
    private sanitizer : DomSanitizer,
    private adminService : AdminService
  ){}

  ngOnInit(): void {
    console.log("Received chat:", this.chat)
    this.getAllEmployeesAndOwners();
  }

  public getAllEmployeesAndOwners() : void {
    this.employeeService.getAllEmployeesAndAdmins().subscribe(
      (response : any) => {
        this.owners = response.Owners;
        this.employees = response.Employees;
        this.getChatName();
      }
    )
  }

  public getChatName() : void {
    if(this.chat.ParticipantRole === 'employee'){
      this.employeeService.getEmployeePicture(this.chat.ParticipantDetails.DefaultPicture, this.chat.ParticipantDetails.EmployeeID).subscribe(
        (imageLink: SafeResourceUrl | null) => {
          this.employeePictureLink = imageLink;
        }
      ); 
    }

    if(this.chat.ParticipantRole === 'owner'){
      this.employeeService.getEmployeePicture(true, this.chat.ParticipantDetails.ID).subscribe(
        (imageLink: SafeResourceUrl | null) => {
          this.employeePictureLink = imageLink;
        }
      ); 
    }

    const employee = this.employees.find(employee => 
      employee.EmployeeID === this.chat.ParticipantDetails.ID
    );

    if(employee) {
      this.employeeService.getEmployee(this.chat.Participants[0]).subscribe(
        (response : Employee) => {
          this.chatName = response.FirstName + ' ' + response.LastName;
          this.employeeService.getEmployeePicture(response.DefaultPicture, response.EmployeeID).subscribe(
            (imageLink: SafeResourceUrl | null) => {
              this.employeePictureLink = imageLink;
            }
          );       
          console.log("CHAT NAME: ", this.chatName);
        }
      )
    } else {
      const owner = this.owners.find(owner => 
        owner.ID === this.chat.Participants[0]
      );

      if(owner) {
        this.adminService.getAdminOrOwnerById(owner.ID).subscribe(
          (response : any) => {
            this.chatName = response.FirstName + ' ' + response.LastName;
            this.employeeService.getEmployeePicture(true, response.ID).subscribe(
              (imageLink: SafeResourceUrl | null) => {
                this.employeePictureLink = imageLink;
              }
            );       
            console.log("CHAT NAME: ", this.chatName);
          }
        )
      }
    }
  }

}
