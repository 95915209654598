<div class="flex flex-col w-full h-full items-start font-poppins p-4 justify-between">
    <div class="flex flex-col w-full h-full items-start gap-4 overflow-y-auto">
        <div class="flex flex-row w-full justify-between items-center">
            @if (hasCompnay) {
                <p class="big-bold text-greyScaleTextTitle">{{translationService.translations?.variables['Choose company']}}</p> 
            } @else {
                <p class="big-bold text-greyScaleTextTitle">{{translationService.translations?.variables['Create a company']}}</p> 
            }
        </div>
        @if (hasCompnay) {
            <p class="text-[14px] font-light text-primaryBlack/50">
                {{translationService.translations?.variables['Here is a list of companies you can manage. Select one to continue using the application.']}}
            </p>        
        } @else {
            <p class="text-[14px] font-light text-primaryBlack/50">
                Press <span class="text-lightGreen font-medium">"Add new"</span> to create your very first company.
            </p>        
        }
        <div class="flex flex-col md:flex-row w-full h-full justify-start overflow-y-auto gap-4">
            <div class="flex flex-col gap-4 overflow-y-auto w-full h-full">
                <button class="flex flex-col items-start rounded-md py-2 px-24 font-poppins gap-2 border-[1px] border-primaryBlack/50 group hover:bg-primaryWhite 
                            items-center justify-center transition-[0.5s] cursor-pointer"
                        (click)="openCreateNewCompanyModal()">
                    <img src="../../../../assets/icons/PlusIcon.png" class="opacity-[50%] group-hover:opacity-[100%]">
                    <p class="text-[36px] font-medium text-primaryBlack opacity-[50%] group-hover:opacity-[100%]">{{translationService.translations?.variables['Add new']}}</p>
        
                </button>
                @for (company of companies.owned; track $index) {
                    <div [ngClass]="{'bg-lightGreen/30 border-[2px] border-lightGreen': isActive(company.id),
                                    'bg-primaryWhite' : !isActive(company.id)}"
                        class="flex relative border-[1px] hover:translate-y-[-5px] transition-[0.5s] hover:border-lightGreen rounded-md
                                cursor-pointer hover:shadpw-md w-full"
                        (click)="setActive(company.id)">
                        <app-company-card class="w-full"
                            [company]="company"
                        />
                    </div>
                }
            </div>
            <div class="flex w-1 h-full bg-primaryBlack/20">

            </div>
            <div class="flex flex-col w-full h-full overflow-y-auto">
                @for (company of companies.administered; track $index) {
                    <div [ngClass]="{'bg-lightGreen/30 border-[2px] border-lightGreen': isActive(company.id),
                                    'bg-primaryWhite' : !isActive(company.id)}"
                        class="flex relative border-[1px] hover:translate-y-[-5px] transition-[0.5s] hover:border-lightGreen rounded-md
                                cursor-pointer hover:shadpw-md w-full"
                        (click)="setActive(company.id)">
                        <app-company-card class="w-full"
                            [company]="company"
                        />
                    </div>
                }
            </div>
        </div>
    </div>
</div>