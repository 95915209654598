import { Component, Input, OnInit } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { EmployeeService } from 'src/app/services/employee.service';

@Component({
  selector: 'app-chat-message',
  standalone: true,
  imports: [],
  templateUrl: './chat-message.component.html',
  styleUrl: './chat-message.component.css'
})
export class ChatMessageComponent implements OnInit{
  @Input() received : boolean = true;
  @Input() content : string = '';
  @Input() message : any;

  public participantImageLink : any;
  public myImgLink: any;

  constructor(private employeeService : EmployeeService){}

  ngOnInit(): void {

    console.log("Received message: ", this.message)
  
    const adminId = sessionStorage.getItem('adminId');

    if(this.message.sender !== adminId){
      this.getProfileImages()
    }
  }


  public getProfileImages() : void {
    if(this.message.senderRole === 'owner') {
      this.employeeService.getEmployeePicture(true, this.message.sender).subscribe(
        (imageLink: SafeResourceUrl | null) => {
          this.participantImageLink = imageLink;
        }
      )
    } else {
      this.employeeService.getEmployeePicture(this.message.participantDetails.DefaultPicture, this.message.sender).subscribe(
        (imageLink: SafeResourceUrl | null) => {
          this.participantImageLink = imageLink;
        }
      )
    }
  }
}
