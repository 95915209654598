import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { catchError, finalize, switchMap, tap, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { inject } from '@angular/core';
import { NgToastService } from 'ng-angular-popup';

export const authInterceptor : HttpInterceptorFn = (req, next) => {
  // console.log(">>>Intercepting - authorization....");
  // console.log("Request: " + req.url);

  const authService = inject(AuthService);
  const toastService = inject(NgToastService);

  const token = sessionStorage.getItem('token');
  const companyId = sessionStorage.getItem('company');

  const excludeFromIntercepting = [
    '/auth/login', 
    '/auth/register?uuid=', 
    '/auth/check/admin/refresh/token', 
    '/auth/signUpWithGoogle?uuid=', 
    '/auth/signInWithGoogle?uuid=',
    '/S3/uploadLogo',
    '/asset/reservation/info/qr/'
  ];

  let authReq = req.clone();

  if(req.url.includes(excludeFromIntercepting[excludeFromIntercepting.length-1])){
    console.log("AEIOU")
    const newCompanyId = sessionStorage.getItem('newCompanyId')
    const newReq = req.clone({
      withCredentials : true,
      setHeaders : {
        'Authorization': `Bearer ${token}`,
        'companyID': `${newCompanyId}`
      }
    });

    return next(newReq).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          if (!authService.isRefreshingToken) {
            authService.isRefreshingToken = true;
            toastService.warning("Session has expired!", "Warning!", 5000);
  
            return authService.refreshToken().pipe(
              switchMap(() => {
                const newToken = sessionStorage.getItem('token');
                const newAuthReq = req.clone({
                  setHeaders: {
                    'Authorization': `Bearer ${newToken}`,
                    'companyID': `${newCompanyId}`
                  }
                });
                return next(newAuthReq);
              }),
              catchError(refreshError => {
                authService.logOut();
                return throwError(() => refreshError);
              }),
              finalize(() => {
                authService.isRefreshingToken = false; 
              })
            );
          } else {
            return authService.tokenRefreshed$.pipe(
              switchMap(() => next(authReq))
            );
          }
        }
  
        return throwError(() => error);
      })
    );
  }

  if (excludeFromIntercepting.some(url => req.url.includes(url))) {
    const newReq = req.clone({
      withCredentials :true
    });
    return next(newReq);
  }

  if (token === null && companyId !== null) {
    authReq = authReq.clone({
      withCredentials: true,
      setHeaders: {
        'companyID': `${companyId}`,
      },
    });
  }

  if (token !== null && companyId === null) {
    authReq = authReq.clone({
      withCredentials: true,
      setHeaders: {
        'Authorization': `Bearer ${token}`,
      },
    });
  }

  if (token !== null && companyId !== null) {
    authReq = authReq.clone({
      withCredentials: true,
      setHeaders: {
        'Authorization': `Bearer ${token}`,
        'companyID': `${companyId}`,
      },
    });
  }

  return next(authReq).pipe(
    catchError((error: HttpErrorResponse) => {
      if (error.status === 401) {
        if (!authService.isRefreshingToken) {
          authService.isRefreshingToken = true;
          toastService.warning("Session has expired!", "Warning!", 5000);

          return authService.refreshToken().pipe(
            switchMap(() => {
              const newToken = sessionStorage.getItem('token');
              const newAuthReq = req.clone({
                setHeaders: {
                  'Authorization': `Bearer ${newToken}`,
                  'companyID': `${companyId}`
                }
              });
              return next(newAuthReq);
            }),
            catchError(refreshError => {
              authService.logOut();
              return throwError(() => refreshError);
            }),
            finalize(() => {
              authService.isRefreshingToken = false; 
            })
          );
        } else {
          return authService.tokenRefreshed$.pipe(
            switchMap(() => next(authReq))
          );
        }
      }

      return throwError(() => error);
    })
  );
}