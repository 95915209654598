<div class="flex items-center justify-center bg-primaryBlack/30 font-poppins">
    <div class="flex flex-col w-full h-full bg-white rounded-md p-4 gap-6">
        <div class="flex flex-row w-full items-center justify-center justify-between">
            <p class="text-[24px] font-semibold text-primaryBlack">Add new chat</p> 
            <button class="flex size-[30px] rounded-md bg-darkGreen items-center justify-center 
                            hover:bg-lightGreen transition-[0.5s]"
                    (click)="modalService.close()">
                <img src="../../../assets/icons/CloseIcon.png" class="size-[20px] nav_menu_icon">
            </button>        
        </div>

        <div class="relative">
            <div class="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                    fill="none" viewBox="0 0 20 20">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                </svg>
            </div>
            <input type="text" [(ngModel)]="searchQuery" (input)="searchEmployees()" id="table-search-users"
                    class="placeholder:italic block pt-2 ps-10 text-sm text-primaryBlack border border-lightGreen rounded-lg w-80 2xl:w-[450px] h-[45px] focus:ring-lightGreen focus:border-lightGreen"
                    [placeholder]="translationService.translations?.variables['Search for an employee']" />
        </div>

        <div class="flex h-full max-h-[420px] overflow-y-auto">
            <div class="flex flex-col w-full gap-2">
                @for (employee of filteredEmployees; track $index) {
                    <div [ngClass]="{'bg-lightGreen/30' : isActive(employee),
                        'hover:bg-greyScaleSurfaceDisabled' : !isActive(employee)}"
                        class="transition-[0.5s] cursor-pointer rounded-md"
                        (click)="setActive(employee)">
                        <app-employee-list-card class="w-full flex-grow"
                            [userId]="employee.EmployeeID"
                            [defaultPicture]="employee.DefaultPicture" 
                            [fullName]="employee.FullName"
                            [email]="employee.Email"
                            [chatModal]="true"
                            [role]="employee.role"
                        />
                    </div>
                }
            </div>
        </div>

        <div class="flex flex-row w-full justify-end">
            <app-button
                [text]="translationService.translations?.variables['Add']"
                [function]="startChat.bind(this)"
            />
        </div>
    </div>
</div>