<div class="flex flex-row w-full border-b border-greyScaleBorderDefault items-center gap-2 p-2 truncate cursor-pointer transition-[0.5s] hover:bg-greyScaleSurfaceSubtle">
    <div class="flex w-auto relative">
        <div class="flex-shrink-0 flex size-12 img-container rounded-full border-[1px] border-primaryBlack/20">
            <img [src]="employeePictureLink" class="rounded-full w-full h-full object-cover" />
        </div>        
        <div class="absolute bottom-0 right-0 size-3 rounded-full bg-lightGreen"></div>
    </div>
    <div class="flex flex-col w-full truncate overflow-x-hidden">
        <div class="flex flex-row w-full items-center justify-between">
            <p class="global text-greyScaleTextTitle">{{chat.ChatName}}</p>
            <p class="global text-greyScaleTextTitle">{{chat.LastMessageDateAndTime}}</p>
        </div>
        <p class="small text-greyScaleTextSubtitle text-ellipsis">{{chat.LastMessage}}</p>
    </div>
</div>