import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ButtonComponent } from '../../button/button.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslationService } from 'src/app/services/translation.service';
import { EmployeeService } from 'src/app/services/employee.service';
import { EmployeeListCardComponent } from '../../employee-list-card/employee-list-card.component';
import { ChatService } from 'src/app/services/chat.service';

@Component({
  selector: 'app-add-new-chat',
  standalone: true,
  imports: [ButtonComponent, CommonModule, FormsModule, EmployeeListCardComponent],
  templateUrl: './add-new-chat.component.html',
  styleUrl: './add-new-chat.component.css'
})
export class AddNewChatComponent implements OnInit{

  @Output() chatCreated = new EventEmitter<void>();

  public searchQuery : string = "";
  public filteredEmployees : any[] = [];
  public employees : any[] = [];
  public extractedData : any[] = [];
  public selectedEmployee : any;

  constructor(public modalService : NgbActiveModal,
    public translationService : TranslationService,
    private employeeService : EmployeeService,
    private chatService : ChatService
  ){}

  ngOnInit(): void {
    this.loadEmployees();
    this.getAllEmployees();
  }

  public searchEmployees(): void {
    if (!this.searchQuery) {
      this.filteredEmployees = this.employees;
    } else {
      this.filteredEmployees = this.employees.filter(employee =>
        employee.FullName.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    }
  }

  public startChat() : void {
    const adminId = sessionStorage.getItem('adminId'); 

    if(adminId && this.selectedEmployee) {
      this.chatService.newChat(adminId, this.selectedEmployee.EmployeeID).subscribe(
        (response : any) => {
          console.log("New chat successfully created!", response);
          this.chatCreated.emit();
          this.modalService.close();
        }
      )
    }
  }

  public loadEmployees() : void {
    this.filteredEmployees = [];
    this.employeeService.getAllEmployeesAndAdmins().subscribe(
      (response : any) => {
        // if(response) {
        //   this.employees = response.map(employee => {
        //     return {
        //       ...employee, 
        //       FullName: employee.FirstName + " " + employee.LastName,
        //     };
        //   });
        //   this.filteredEmployees = this.employees
        //   console.log(this.employees);
        // }

        if(response) {
          response.Employees.map((employee: { EmployeeID: string; FirstName: string; LastName: string; Email: string; }) => {
            const emp = {
              EmployeeID : employee.EmployeeID,
              FullName : employee.FirstName + " " + employee.LastName,
              Email : employee.Email,
              role : "Employee"
            }

            this.filteredEmployees.push(emp);
          });

          response.Owners.map((owner: { ID: any; FirstName: string; LastName: string; Email: any; }) => {
            const own = {
              EmployeeID : owner.ID,
              FullName : owner.FirstName + " " + owner.LastName,
              Email : owner.Email,
              role : "Owner"
            }

            this.filteredEmployees.push(own);
          });

          console.log("Available contacts: ", this.filteredEmployees);
        }
      }
    );  
  }

  public getAllEmployees() : void {
    this.employeeService.getAllEmployeesAndAdmins().subscribe(
      (response : any) => {
        console.log("FETCHED: ", response);
      }
    )
  }

  public isActive(employee : any) : boolean {
    return this.selectedEmployee === employee;
  }

  public setActive(employee : any) : void {
    if(this.selectedEmployee === employee){
      this.selectedEmployee = null;
    } else {
      this.selectedEmployee = employee;
    }
  }
}
